/* ==========================================================================
  main.js
========================================================================== */

/**
 * Init dropdown-menus
 */
var dropdown_menus = document.querySelectorAll('.js-navigation');
for (var i = 0; i < dropdown_menus.length; i++) {
  component_dropdown_menu({
    desktop_min_width: 890,
    menu: dropdown_menus[i]
  });
}

/**
 * Init mobile menu
 */
component_mobile_menu({
  menu:     document.querySelector('.js-mobile-menu'),
  site:     document.querySelector('.js-page'),
  toggles:  document.querySelectorAll('.js-menu-toggle')
});

/**
 * Init polyfill object-fit for lazyloaded
 */
if (typeof objectFitPolyfill === "function") {
  document.addEventListener('lazybeforeunveil', function(e){
    objectFitPolyfill();
    e.target.addEventListener('load', function() {
      objectFitPolyfill();
    });
  });
}

/**
 * Init lightbox
 */
if (document.querySelectorAll('.lightbox').length) {
  try {
    new Tobi({
      // variables from script localization in register-assets.php
      navLabel: [theme_strings.prev, theme_strings.next],
      closeLabel: theme_strings.close,
      loadingIndicatorLabel: theme_strings.loading,
      captionsSelector: 'self',
      captionAttribute: 'data-caption',
      zoom: false,
    });
  } catch (e) {
    console.log(e);
  }
}

/**
 * Init home hero header slider
 */
if ( document.querySelectorAll('.hero__slider').length ) {

  var slider = tns({
    container             : '.hero__slider',
    items                 : 1,
    autoplay              : true,
    autoplayTimeout       : 3000,
    autoplayButtonOutput  : false,
    speed                 : 500,
    controls              : false,
    nav                   : true,
    navPosition           : 'bottom'
  });

}

function scrollToTop() {
  window.scroll({top: 0, left: 0, behavior: 'smooth'});
}

/**
 * Init ANEFA slider
 */
var sliderAnefa;

if ( document.querySelectorAll('.anefa-slider--active').length ) {

  sliderAnefa = tns({
    container             : '.anefa-slider--active',
    items                 : 1,
    autoplay              : true,
    autoplayTimeout       : 3000,
    autoplayButtonOutput  : false,
    speed                 : 500,
    nav                   : true,
    navPosition           : 'bottom',
    controls              : false,
  });

}

/**
 * Youtube embed poster frame reveal
 * Hint : Need jQuery
 * @source: https://codepen.io/pixelthing/pen/zGZKaQ
 */
jQuery(document).on('click','.js-videoPoster',function(ev) {

  ev.preventDefault();
  // retrieve current poster & wrapper
  var $poster = jQuery(this);
  var $wrapper = $poster.closest('.js-videoWrapper');
  // play video
  var $iframe = $wrapper.find('.js-videoIframe');
  // var src = $iframe.data('src');
  var src = $iframe.data('src');
  // hide poster
  $wrapper.addClass('videoWrapperActive');
  // add iframe src in, starting the video
  $iframe.attr('src',src);

  // stop slider
  if(sliderAnefa) {
    sliderAnefa.pause();
  }

});

/**
 * jQuery globals functions
 */
jQuery(document).ready(function($){

  /**
   * Attach Select2JS plugin to <select> element with select2js class
   * If custom class "slect2js--alt" is finded, change select2js containerCssClass option (pink to blue arrow)
   */

    var $select = $('.select2js');
    var cssClass = 'select2-selection--pink-arrow';

    if($select.hasClass('select2js--alt')) {
      cssClass = 'select2-selection--blue-arrow';
    }

    $('.select2js').select2({
      minimumResultsForSearch: -1,
      width: '100%',
      containerCssClass: cssClass
    });

});

/**
 * Mobile (890px) : Sticky menu on scroll
 */

let scrollpos = window.scrollY;
let windowWidth = window.innerWidth;
const header = document.querySelector(".site-header");
const header_height = header.offsetHeight;
const add_class_on_scroll = () => header.classList.add("site-header--sticky");
const remove_class_on_scroll = () => header.classList.remove("site-header--sticky");

function stickyMenu() {
  scrollpos   = window.scrollY;
  windowWidth = window.innerWidth;

  if ( ( scrollpos >= header_height ) && ( window.innerWidth < 890 ) ) {
    add_class_on_scroll();
  } else {
    remove_class_on_scroll();
  }

}

window.addEventListener('scroll', function() {
  stickyMenu();
});

stickyMenu();
